<template>
<g-card page-icon="mdi-mailbox" page-title="Mail Box">
  <g-list-page
      elevation="elevation-0"
      :headers="headers"
      :show-selected="true"
      stateendpoint="mailBox.mailBoxes"
      @loadData="loadData"
      @editItem="editItem"
      @deleteItem="deleteItem"
      @destroyItem="destroyItem"
      @restoreItem="restoreItem"
      @switchHandler="switchHandler"
      @selectedItemHandler="selectedItemHandler"
  >
    <template v-slot:overlay>
      <page-overlay :overlay="isOverlay" />
    </template>
    <template v-slot:top>
      <v-row>
        <v-col cols="12" md="6" sm="8">
          <g-p-search
              hint="Minimum two characters needed to search. Columns(name)"
              @applySearch="handleSearch"
          />
        </v-col>
        <v-col cols="12" md="6" sm="4">
          <add-button
              text="Email"
              :toolbar="true"
              @onButtonClick="openPopUp"
              class="ma-2 justify-end"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="3" sm="12">
          <v-select
              v-model="currentPaging.mailTypes"
              label="Client Type"
              :items="clientTypes"
              item-text="text"
              item-value="value"
              chips
              multiple
              dense
              outlined
              @change="onChangeClientType()"
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <g-p-auto-complete
              :dense="true"
              :outlined="true"
              :status=true
              :validation="false"
              :search-value="templateTypes"
              apiPath="mail-templates"
              search-label="Send Mail: Select a Template"
              @selectSearchResult="selectMailTemplateHandler"
              @clearSearchResult="selectMailTemplateHandler"
          />
        </v-col>
        <v-col cols="12" md="2" sm="12">
          <v-select
              label="Select a Mail"
              v-model="mailer"
              :items="mailSenders"
              item-value="id"
              item-text="name"
              outlined
              dense
          />
        </v-col>
        <v-col cols="12" md="2" sm="6">
          <v-checkbox v-model="isSendMailToAll" color="indigo">
            <template v-slot:label>
              <div>
                Send Email to All
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="2" sm="6">
          <add-button
              text="Send Mail"
              icon="mdi-mail"
              @onButtonClick="newsLetterHandler()"
          />
        </v-col>
      </v-row>
    </template>
  </g-list-page>
  <component
      :is="popupComponent"
      ref="component"
      :basic-data="basicData"
      :item="editedItem"
      :show="showPopup"
      @closePopupHandler="closePopupHandler"
      @onPopupShow="openPopUp"
      @saveItemHandler="saveItemHandler"
  />
  <confirm-popup
      :show="showConfirm"
      confirm-event="onConfirmItem"
      @closePopupHandler="showConfirm = false"
      @onConfirmItem="confirmEvent(currentSelectedItem, true)"
  >
    <div>
      <!-- eslint-disable-next-line  -->
      <span>{{ confirmTxt }}</span>
    </div>
  </confirm-popup>
</g-card>
</template>

<script>
import GCard from "../components/GCard";
import GListPage from "../components/list/GListPage";
import PageOverlay from "../components/PageOverlay";
import GPSearch from "../components/GPSearch";
import AddButton from "../components/buttons/AddButton";
import ListMixins from "../components/mixins/list/List";
import ConfirmPopup from "../components/popups/ConfirmPopup";
import MailBoxPopup from "../components/popups/MailBoxPopup";
import GPAutoComplete from "../components/GPAutoComplete";
import User from "../services/User";
import UserMixin from "../components/mixins/UserMixin";
import pTable from "../json/pTable.json";
export default {
  name: "MailBox",
  components: {
    GPAutoComplete,
    ConfirmPopup,
    AddButton,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard
  },
  mixins: [ListMixins, UserMixin],
  data() {
    return {
      mailer: "",
      selectedItems: [],
      clientIcon: "mdi-account-network",
      isSendMailToAll: false,
      templateTypes: null,
      basicData: {
        isActive: true,
        mailType: 1
      },
      actions: {
        load: 'loadMailBoxes',
        store: 'storeMailBox',
        update: 'updateMailBox',
        destroy: 'destroyMailBox',
        remove: 'mailBoxSoftDeleteHandler',
        sendMail: 'sendNewsLetter'
      },
      clientTypes: [
        {
          text: 'General Clients',
          value: 1
        },
        {
          text: 'Fixed Client',
          value: 2
        },
        {
          text: 'Special Clients',
          value: 5
        },
        {
          text: 'More Special Clients',
          value: 4
        },
        {
          text: 'Agency Clients',
          value: 3
        },
        {
          text: 'Buyers',
          value: 6
        },
        {
          text: "Others",
          value: 7
        },
      ],
      headers: [
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Status',
          value: 'isActive'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      mailSenders: [
        {name: 'Support', id: 'support'},
        {name: 'Admin', id: 'admin'},
        {name: 'Info', id: 'info'},
        {name: 'Payment', id: 'payment'},
        {name: 'Contact', id: 'contact'},
        {name: 'Pbn', id: 'pbn'},
        {name: 'Guest Posting', id: 'guestposting'},
      ]
    };
  },
  created() {
    this.currentPaging.mailTypes = [1];
  },
  mounted() {
    User.auth()
        .then(({data})=> {
          if(!(pTable.settings.mailBox.find(item => item === data.email))) {
            this.$router.push({path: '/'});
          }
        })
        .catch(error=> {
          this.$router.push({path: '/'});
        })
  },
  computed: {
    popupComponent() {
      return MailBoxPopup;
    },
  },
  methods: {
    selectedItemHandler(items) {
      this.selectedItems = items;
    },
    onChangeClientType() {
      this.loadData({isPagingReset: false})
    },
    selectMailTemplateHandler(selections) {
      if(selections) {
        this.templateTypes = selections;
      } else {
        this.templateTypes = null;
      }
    },
    async newsLetterHandler(item, confirm = false) {
      if(!(this.currentPaging.mailTypes.length > 0) || !this.templateTypes || !this.mailer) {
        this.setSnackBar({text: 'Client Types, Select Template and Sender Email Fields are Mandatory', type: 'error'});
        return;
      }
      if(this.selectedItems.length === 0 && !this.isSendMailToAll) {
        this.setSnackBar({text: 'No. of selected email is empty, Please select one or more or checked:send emails to all', type: 'error'});
        return;
      }
      this.confirmEvent = this["newsLetterHandler"];
      this.confirmTxt = "Are you sure, You want send email?";
      if (!confirm) {
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      this.isOverlay = true;
      try {
        let {data:{message}} = await this.$store.dispatch(this.actions.sendMail, {
          selectedItems: this.selectedItems,
          templateType: this.templateTypes.id,
          mailer: this.mailer,
          isSendMailToAll: this.isSendMailToAll,
          mailTypes: this.currentPaging.mailTypes.toString()
        })
        this.setSnackBar({text: message, type: "info"});
        this.isOverlay = false;
      } catch (errors){
        this.setSnackBar({text: "Data Validation error", type: "error"});
        this.isOverlay = false;
      }
    }
  },
}
</script>

<style scoped>
.v-input--selection-controls{
  margin-top: 0 !important;
  padding-top: 4px !important;
}
</style>